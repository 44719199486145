.app {
  background-color: lightgrey;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.centered-container {
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: space-between;
}

.sub-container {
  width: calc(20% - 10px);
  /* 20px for left and right margins */
  height: calc(100% - 10px);
  /* 20px for top and bottom margins */
  background-color: lightgrey;
  margin: 5px;
  border-radius: 5px;
}

.sub-container.no-bg {
  background-color: transparent;
}

.sub-container.big {
  width: calc(60% - 10px);
  /* 20px for left and right margins */
}
.sub-container.bbig {
  width: calc(80% - 10px);
  /* 20px for left and right margins */
}
/* App.css */
/* App.css */
.nav-menu {
   display: flex;
   flex-direction: column;
   /* align-items: center; */
   justify-content: center;
   /* width: 100%; */
   list-style-type: none;
   padding: 0;
   margin-left: 10px;
}

.nav-menu li {
  margin-bottom: 10px;
}

.nav-menu li span {
  margin-left: 10px;
  transition: color 0.3s;
  /* Add transition for smoother color change */
}
.nav-menu .selected span,
.nav-menu .selected svg {
  /* Apply green color to both span and svg for selected item */
  color: green;
}

.nav-menu li:hover span,
.nav-menu li:hover svg {
  /* Apply hover effect to both span and svg */
  color: grey;
  /* Change text color to green on hover */
}
/* App.css */
.year-list {
  padding: 5px;
  /* display: flex; */
  /* flex-wrap: wrap; */
}

.year {
  background-color: white;
  border-radius: 10px;
  padding: 5px 10px;
  margin: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.year.selected {
  background-color: grey;
  color: white;
}
.year-margin{
  margin: 10px;
}
.year-list-container {
  height: 100%;
  /* Set a fixed height */
  overflow-y: auto;
  /* Enable vertical scrolling */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.iframe-container{
  height: 100%;
  width: 100%;
}
.iframe-d{
  height: 100%;
    width: 100%;
}
.graph-display{
  z-index: 3;
  /* display: flex;
  flex-wrap: wrap; */
}
.graph-container{
  display: inline-block;
  width: 60%;
}
.pie{
  /* display: inline-block; */
  width: 25%;
}
.histo{
  width: 25%;
}

.chatbot-container {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: 10px;

  /* align-items: right */
}

.chatbot {
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
}

.chatbot-messages {
  height: 400px;
  overflow-y: auto;
  padding: 10px;
  background-color: white;
}

.message {
  margin-bottom: 10px;
  padding: 8px 12px;
  border-radius: 5px;
  max-width: 70%;
}

.user {
  background-color: #4caf50;
  color: white;
  align-self: flex-end;
}

.bot {
  background-color: #2196f3;
  color: white;
}

.input-form {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #f9f9f9;
}

.input-field {
  flex-grow: 1;
  margin-right: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 8px;
  font-size: 14px;
}

.send-button {
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  background-color: #4caf50;
  color: white;
  cursor: pointer;
}

.float-button {
  /* position: fixed;
  bottom: 20px;
  right: 20px; */
  position: relative;
  right: 0;
  width: 50px;
  height: 50px;
  background-color: #4caf50;
  color: white;
  border-radius: 50%;
  text-align: center;
  font-size: 24px;
  line-height: 50px;
  cursor: pointer;
}
